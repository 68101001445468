import React from "react"
import {Link, graphql, useStaticQuery} from 'gatsby'

import Header from "../components/header.js";

import  '../styles/blog.css'

import Seo from "../components/seo.js"

import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from "../components/layout"

const BlogPage = () => {
    const data = useStaticQuery(graphql `
    query {
        allContentfulBlogPost (
          sort: {
            fields: publishedDate,
            order: DESC
          }
        ) {
          edges {
            node {
              title
              slug
              preview
              publishedDate(formatString: "MMMM Do, YYYY")
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `)

    return (
      <Layout pageInfo={{
        pageName: "blog"
    }}>
        <div>
        <Seo title="Blog"/>
        <Header />
            <div style={{paddingTop: "0px", minHeight: "90vh"}}>
              <div >
              <h3 style={{textAlign: "center"}}>Blog</h3>
              </div>
            
            <div className="blog-row main-blog" style={{width: "95%"}}>
              <ol>
                {data.allContentfulBlogPost.edges.map((edge) => {
                    return (
                      
                      <div className="column blog-column">
                        <li className="posts">
                          <Link to ={`/blog/${edge.node.slug}`} >
                          <img src={edge.node.image.file.url} style={{width: "100%"}}/>
                            <h4 style={{color: "black"}}>{edge.node.title}</h4>
                            <p>{edge.node.publishedDate}</p>
                            <p>{edge.node.preview}</p>
                          </Link>
                        </li>
                      </div>
                    )
                })}
            </ol>
            </div>
            </div>
        </div>
        </Layout>
    )
}

export default BlogPage